<template>
  <div>
    <!--begin::ListOperation-->
    <OperationListComponent v-bind="this.componentOptions"></OperationListComponent>
    <!--end::ListOperation-->
  </div>
</template>

<script>
import OperationListComponent from "@/view/content/neoopatrace/components/OperationListComponent.vue";

export default {
  name: "list-operation",
  components: {
    //NeoOpatrace
    OperationListComponent,
  },
  data() {
    return {
      componentOptions: {
        itemsLimit: 0,
        itemsPerPage: 50,
        itemsOrder: "ASC",
        showCreateButton: false,
        showSearch: true,
        showFilters: true,
        showPagination: true,
      },
    };
  },
};
</script>
